.clients-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 100px 0;
    gap: 2rem;
}

.clients-card{
    padding: 20px;
    background-color: var(--card-second-color);
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.clients-card img{
    height: 150px;
}