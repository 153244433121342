.horizontal-line {
  width: 17%;
  background-color: var(--primary-color);
  height: 100vh;  
  position: absolute;
  top: 0;
  right: 14%;
  z-index: -1;
}
.play-anim-container {
  background-color: var(--primary-color);
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 98vh;
  color: var(--secondary-color);
  text-transform: uppercase;
}
.hero-section-text {
  text-align: center;
  z-index: 1000;
}
.hero-section-text P:nth-of-type(1) {
  font-size: 2.4em;
  font-weight: 500;
}
.hero-section-text P:nth-of-type(2) {
  font-size: 11em;
  font-weight: bold;
  line-height: 0px;
  margin-top: 4rem;
}

.hero-section-explore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.hero-section-explore .creative,
.hero-section-explore .explore {
  /* margin-right: 5px; */
  font-size: 1em;
  font-weight: 500;
  flex: 1;
  padding: 15px 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-section-explore .creative {
  background-color: var(--primary-color);
  font-weight: 500;
  white-space: nowrap;
  animation: creativeAni 3s infinite;
}
.hero-section-explore .or {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-65%, -50%);
  background-color: #000;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
}

.hero-section-explore .explore {
  background-color: var(--gray);
  justify-content: start;
  animation: exploreAni 3s infinite;
}
.hero-footer {
  position: absolute;
  bottom: -5px;
  left: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  z-index: 10002;
}
a {
  text-decoration: none;
  color: inherit !important;
}
.play-icon {
  width: 50px;
}

.hero-footer p {
  font-size: 1.3rem;
  line-height: 0px;
}

.hero-footer ul {
  display: flex;
  list-style: none;
}

.social-media {
  gap: 10px;
  transition: all 0.3s ease-in-out;
}
.social-media li:first-of-type,
.social-media li:hover {
  color: var(--primary-color);
}
.hero-footer ul li.active {
  color: var(--primary-text-color);
}

@keyframes exploreAni {
  25% {
    background-color: #545454;
  }

  50% {
    background-color: #54545465;
  }

  75% {
    background-color: #545454cc;
  }

  100% {
    background-color: #545454;
  }
}

@keyframes creativeAni {
  25% {
    background-color: #ec1414d9;
  }

  50% {
    background-color: #ec141430;
  }

  /* 75% {
    background-color: #ec1414bb;
  } */

  100% {
    background-color: #ec1414ea;
  }
}

@media (max-width: 1024px) {
  .hero-section-text {
    scale: 0.9;
  }
}
@media (max-width: 988px) {
  .horizontal-line {
    width: 10%;
    right: 0;
  }
  .hero-section-text {
    scale: 0.7;
    margin-bottom: -40px;
    margin-top: -150px;
  }
  .hero-section-explore {
    width: 70%;
  }
  .explore {
    justify-content: center;
  }
 
}
@media (max-width: 768px) {
  .hero-section{
    justify-content: start;
    margin-top: 200px;
  }
  .hero-section-text {
    scale: 0.6;
  }
  .hero-section-explore {
    width: 98%;
    margin: auto;
    margin-top: 0;
  }
  .explore {
    justify-content: center;
  }

  .hero-footer {
    flex-direction: column;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    gap: 10px;
  }
  .social-media-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 623px) {
  .hero-section-text {
    scale: 0.5;
  }
  .hero-section-explore {
    width: 88%;
    margin: auto;
    margin-top: 0;
  }
}
@media (max-width: 428px) {
  .hero-section-text {
    scale: 0.3;
  }
  .hero-section-text P:nth-of-type(1) {
    font-size: 2.7em;
    margin-bottom: 10px;
  }
 
}
