footer {
  background-color: var(--primary-color);
}


.footer{
 
}

.footer-links {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.footer-links ul {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 100px;
  padding: 50px 40px;
  gap: 2rem;
  color: aliceblue;
}
.pro:nth-last-of-type(1) {
grid-column: span 2 / span 2 ;

}
.footer-developer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 10px 40px;
  background-color: var(--dark); 
}
.motto {
  font-weight: bold;
}
.motto-text {
  font-size: 14px;
}
.about-ix{
  margin-right: 20px;
}
.right {
  display: flex;
  flex-direction: column;
}
.socialMedia ul {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}
.social-account{
  display: flex;
  gap: 1rem;
}

.social-account .icon .bi{
  font-size: 30px;
}

ul {
  list-style: none;
  margin-block-start: 0em;
  padding-inline-start: 0px;
}
.socialMedia ul li {
  background-color: #ffffff;
  box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.42);
  -webkit-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.42);
  padding: 10px;
  border-radius: 5px;
}
.left {
  display: flex;
  justify-content: space-around;
  /* gap: 8rem; */
}
.list-title {
  font-weight: bold;
  font-size: 17px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.pro ul li {
  margin: 8px 0;
}
.pro{
  display: flex;
  flex-direction: column;
}
.address {
  margin-top: 10px;
}

.bg-overplay {
  background-color: transparent;
  background-image: linear-gradient(180deg, var(--e-global-color-b958562) 0%, var(--e-global-color-1ba5bce) 100%);
  opacity: 1;
  mix-blend-mode: multiply;
}

.visit{
  margin-bottom: 6px;

}
.foot-sub-title{
  font-weight: bold;
  text-transform: uppercase;
}

.phone-container{
  display: flex;
  align-items: start;
  gap: 10px;
}
.phone-div{
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.subscribe-container{
  display: flex;
  flex-direction: column;
}
.sub-form{
  display: flex;
  gap:10px;
  padding:10px 0;
  align-items: start;
}
.sub-form input{
  padding: 8px 10px;
  border: none;
  outline: none;
}
.sub-btn{
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: var(--dark);
  color: aliceblue;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
}


.footer-logo{
  width: 120px !important;
}

/* For screens smaller than 576px (extra small devices) */
@media screen and (max-width: 575.98px) {
  /* CSS rules for extra small devices go here */
  .footer-content{
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
  .footer-developer{
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-align: center;
  }
  .pro:nth-last-of-type(5) {
    grid-column: span 2 / span 2 ;
    
    }
}

/* For screens between 576px and 767px (small devices) */
@media screen and (min-width: 576px) and (max-width: 767.98px) {
  /* CSS rules for small devices go here */
 
}

/* For screens between 768px and 991px (medium devices) */
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  /* CSS rules for medium devices go here */
  .sub-form{
    flex-direction: column;
  }
  .footer-logo{
    width: 140px !important;
  }
 
}

/* For screens between 992px and 1199px (large devices) */
@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  /* CSS rules for large devices go here */
}

/* For screens larger than or equal to 1200px (extra large devices) */
@media screen and (min-width: 1200px) {
  /* CSS rules for extra large devices go here */
}
