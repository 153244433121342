.cat{
    margin: 30px 0;
    margin-bottom: 150px;
    color: var(--light);
    display: flex;
    gap: 1rem;
    justify-content: space-around;
    align-items: center;
    background-color: var(--primary-color);
    height: min-content;
    padding: 0 40px;
}

.cat-hero{
    font-weight: bold;
    font-size: 2.5em;
}

.cat-sub{
    color: aliceblue;
    font-size: 17px;
}

.cat-btn-section{
    display: flex;
    gap: 1rem;
    margin: 15px 0;
}

.cat-btn{
    padding: 8px 15px;
    font-size:17px;
    border-radius: 0.23rem;
}

.cat-btn-connect{
    background-color: black;
}
.cat-btn-request{
    background-color: white;
    color: var(--dark);
}
.cat-img img{
    width: 100%;
    height: 100%;
}
.cat-section{
    padding: 0 50px;
}
@media (max-width: 800px) {
    .cat-hero{
        line-height: 2.5rem;
        margin-bottom: 5px;
    }
}
@media (max-width:428px) {
    .cat{
        flex-direction: column-reverse;
    }
    
   
}