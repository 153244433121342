:root {
  --primary-color: #a62121;
  --primary-color-transparent: #ec1414d9;
  --dark-color-transparent: #000000b9;
  --secondary-color: #ebebeb;
  --gray: #545454;
  --grayscale: #5454547c;
  --primary-text-color: #bc1111;
  --card-first-color: #3c3c3c;
  --gray-text: #d9d9d9;
  --gray-text-transparent: #c3c3c382;
  --card-second-color: #5351516e;
  --dark: #000;
  --light: #fff;
  --card: #282828;
}
* {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  transition: all 0.4s ease-in-out;
}
@font-face {
  font-family: "poppins";
  src: url(assets/Fonts/Poppins-Regular.ttf);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
}

.content-heder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.content-back {
  position: absolute;
  text-transform: uppercase;
  font-size: 10em;
  font-weight: bold;
  color: var(--gray);
}
.content-header-motto {
  z-index: 1000;
  font-size: 2.4em;
  font-weight: bold;
  margin-top: 20px;
  text-transform: uppercase;
  color: #fff;
}
.content-header-motto span {
  /* background-color: var(--primary-color); */
  background: linear-gradient(90deg, #ff0000, rgba(255, 3, 3, 0.142));
  padding: 10px 15px;
}
