.navbar {
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 30px;
  color: aliceblue;
}
.navbar .logo {
  width: 60px;

}
.logo img {
  width: inherit;
}

.links ul {
  list-style: none;
  display: flex;
  z-index: 2000 !important;
}

.links ul li {
  margin: 0 15px;
  /* font-family: poppins; */
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  text-underline-offset: 10px;
}

.links ul li.active,
.links ul li:hover {
  color: rgb(255, 255, 255);
  text-decoration: underline;
  text-underline-offset: 10px;
}

.links {
  position: absolute;
  right: 0;
  top: 55px;
  height: 100vh;
  width: 100%;
  background-color: var(--dark-color-transparent);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.navbar.active .links {
  /* transform: translate(0, 0); */
  /* transform: scale(1); */
  opacity: 1;
  border-radius: 0%;
}
.navbar.active {
  justify-content: flex-end;
}
.navbar.active .logo {
  position: absolute;
  left: 50%;
  top: 25%;
  z-index: 100001;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  transform: translateX(-50%);
  background-color: black;
  padding: 20px 0;

}

.navbar.active .logo img {
  width: 100px;
}
.logo-motto,
.logo-text {
 display: none;
}
.navbar.active .logo-text {
  display: block;
  font-size: 1.5em;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.navbar.active .logo-motto{
  display: block;
  font-size: medium;
  text-transform: uppercase;
  font-weight: 500;
  color: #eaeaea;
}


@media screen and (max-width: 500px) {
  .navbar .logo {
    top: 55px;
  }
}

.order {
  background-color: var(--primary-color);
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px var(--primary-color);
  text-transform: uppercase;
  border-width: 500px;
  cursor: pointer;
}
.menu-container {
  display: flex;
  flex-direction: column;
  align-items: end;
  height: auto;
  width: 40px;
  gap: 5px;
  cursor: pointer;
}
.menu-container.active .menu:nth-of-type(1) {
  transform: rotate(45deg) translate(7px, 7px);
}
.menu-container.active .menu:nth-of-type(2),
.menu-container.active .menu:nth-of-type(4) {
  transform: scale(0);
}
.menu-container.active .menu:nth-of-type(3) {
  transform: rotate(-45deg) translate(7px, -7px);
}

.menu-container .menu {
  background-color: rgb(255, 255, 255) !important;
  height: 5px;
  width: 100%;
  border-radius: 2px;
}
.nav-btn {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.menu-container .menu:nth-of-type(1),
.menu-container .menu:nth-of-type(3) {
  width: 75% !important;
}
@media (max-width: 768px) {
  .links {
    display: none;
  }
  .order {
    display: none;
  }
}
