.skill {
  margin: 50px;
  margin-bottom: 150px;
  color: var(--light);
}

.skill-heder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.skill-back {
  position: absolute;
  text-transform: uppercase;
  font-size: 10em;
  font-weight: bold;
  color: var(--gray);
}
.skill-header-motto {
  z-index: 1000;
  font-size: 2.4em;
  font-weight: bold;
  margin-top: 20px;
  color: #fff;
}
.skill-header-motto span {
  /* background-color: var(--primary-color); */
  background: linear-gradient(90deg, #ff0000, rgba(255, 3, 3, 0.142));
  padding: 10px 15px;
}
.project-container {
  margin-top: 100px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 45%;
  gap: 10px !important;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
}
.project-container::-webkit-scrollbar {
  display: none;
}
.snap-container {
  scroll-snap-type: line mandatory;
}
.snap-container > * {
  scroll-snap-align: start;
}

.projects-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  width: 70%;
  margin: 100px auto;
}

.project-card {
  width: 100%;
}

.project-card:nth-of-type(1) {
  grid-row: 1 / 3;
}
.project-card:nth-of-type(2) {
  grid-row: 1 / 3;
}
.project-card img {
  width: 100%;
  height: 100%;
}


/* Mobile devices (up to 767px) */
@media screen and (max-width: 767px) {
  /* CSS rules for mobile devices */
  .projects-card-container {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
  .project-card:nth-of-type(1) {
  grid-row: none;
}
.project-card:nth-of-type(2) {
  grid-row: none;
}
}

/* Tablets and small desktops (768px - 991px) */
@media screen and (min-width: 768px) and (max-width: 991px) {
  /* CSS rules for tablets and small desktops */
  .project-container {
    grid-auto-columns: 100%;
  }
  .project-card {
    width: 100%;
  }
  .skill-back {
    font-size: 7em;
  }
  
}

/* Larger desktops (992px and above) */
@media screen and (min-width: 992px) {
  /* CSS rules for larger desktops */
}
