.consultant {
  margin: 100px 0;
}
.consult-container {
  width: 80%;
  background: linear-gradient(
    140deg,
    var(--primary-color),
    var(--primary-text-color)
  );
  color: var(--secondary-color);
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 30vh;
  padding: 40px 30px;
}
.consult-header {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  color: var(--light);
  width: 50%;
  padding: 20px 30px;
}

.consult-text {
  font-size: 1.5em;
}
.free-consult {
  font-size: 2em;
  font-weight: bold;
}

.consult-header p {
  color: var(--gray-text);
  font-size: 1em;
}

.consult-form {
  display: flex;
  gap: 15px;
  align-items: center;
}

.consult-form input {
  height: 3rem;
  background-color: var(--dark);
  border: none;
  outline: none;
  padding: 0 10px;
  font-size: 1em;
  color: var(--light);
  flex: 1;
}

.consult-form input::placeholder {
  text-transform: uppercase;
  color: var(--gray-text);
}

.corner {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: transparent;
  background-image: linear-gradient(
    180deg,
    var(--e-global-color-b958562) 0%,
    var(--e-global-color-1ba5bce) 100%
  );
  opacity: 1;
  mix-blend-mode: multiply;
}
.btn-sent {
  background-color: var(--dark);
  color: var(--light);
  border: none;
  outline: none;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
}
/* For screens smaller than 576px (extra small devices) */
@media screen and (max-width: 575.98px) {
  /* CSS rules for extra small devices go here */
  .free-consult {
    white-space: nowrap;
    font-size: 1.5em;
  }

  .corner {
    position: absolute;
    top: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    opacity: 0.5;
  }
  .consult-form input {
    height: 2rem;
  }
  .consult-header {
    right: 50%;
    transform: translateX(50%);
    width: fit-content;
    padding: 20px;
  }
  .consult-form {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
}

/* For screens between 576px and 767px (small devices) */
@media screen and (min-width: 576px) and (max-width: 767.98px) {
  /* CSS rules for small devices go here */
}

/* For screens between 768px and 991px (medium devices) */
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  /* CSS rules for medium devices go here */
  .consult-header{
  }
}

/* For screens between 992px and 1199px (large devices) */
@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  /* CSS rules for large devices go here */
}

/* For screens larger than or equal to 1200px (extra large devices) */
@media screen and (min-width: 1200px) {
  /* CSS rules for extra large devices go here */
}
