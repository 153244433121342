.contact-info{
    color: var(--light);
    padding: 50px 40px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: 100px;
}

.contact-icon .bi{
    width: 60px;
    height: 60px;
}

.contact-info-header{
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: var(--light);
}
.contact-info-sub{
    font-weight: 500;
    margin-bottom: 10px;
    color: aliceblue;
}

.contact-info-links{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.contact-info-links >*{
    text-decoration: underline;
    color: rgb(227, 227, 227) !important;
}

.contact-card{
    background-color: var(--primary-color);
    /* overflow: hidden; */
}

.contact-icon{
    background-color: var(--dark);
    position: relative;
    padding: 20px 15px;
    border-bottom-right-radius:100px ;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-info-cont{
    padding: 10px 12px;
}