.service {
  padding: 40px 30px;
}

.service-sub-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 30px;
}

.service-card {
  background: linear-gradient(
    140deg,
    var(--card-first-color),
    var(--card-second-color)
  );
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-color);
  position: relative;
  border: 1px solid var(--secondary-color);
  transition: 1s ease-out;
  cursor: pointer;
}

.service-number {
  position: absolute;
  left: 10px;
  bottom: 5px;
  font-size: 2.5em;
  font-weight: bold;
  color: #595959;
}
.service-text {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.3em;
}

.service-card:first-child,
.service-card:hover {
  background: linear-gradient(140deg, #d63435, #91050b);
}

.service-card:hover {
  scale: 1.1;
}

.service-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "firstCard secondCard fourCard"
    "firstCard threeCard fourCard";
  gap: 1rem;
  width: 70%;
  margin: auto;
  margin-top: 100px;
}
.card-strategy {
  padding: 20px;
  flex-direction: column;
  overflow: hidden;
  height: 25vh !important;
}

.card-strategy-1 {
  grid-area: firstCard;
  height: 93% !important;
}
.card-strategy-2 {
  grid-area: secondCard;
}
.card-strategy-3 {
  grid-area: threeCard;
}
.card-strategy-4 {
  grid-area: fourCard;
  height: 93% !important;
}
.card-strategy-2 {
  grid-column: 2 / 2;
  background-color: #595959 !important;
}
.service-icon {
  font-size: 2.5em;
  background-color: var(--dark);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  border-radius: 50%;
  height: 80%;
  padding: 10px;
  position: absolute;
  top: -50%;
}

.service-icon .icon {
  color: var(--primary-color) !important;
  width: 45px !important;
  height: 45px !important;
}
@media (max-width: 1024px) {
  .service-sub-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .service-card:first-child {
    /* grid-column: 1 / 3; */
    background-color: #595959;
  }
}

@media (max-width: 834px) {
  .service-sub-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .service-card {
    /* grid-column: 1 / 3; */
    background-color: #595959;
  }
  .card-strategy {
    height: 20vh !important;
  }
  .service-container {
    width: 100%;
  }
  .service-container {
    grid-template-areas:
      "firstCard secondCard fourCard"
      " threeCard threeCard threeCard";
  }
}

@media (max-width: 428px) {
  .service-container {
    grid-template-columns:1fr;
    grid-template-areas:
      "firstCard"
      "secondCard"
      "threeCard"
      "fourCard";
  }
}
