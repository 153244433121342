.expo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1em;
  position: relative;
  height: 4rem;
  z-index: 10002;
}

.expo {
  background-color: var(--primary-color);
  padding: 8px 38px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.explore {
  background-color: var(--gray);
  justify-content: center;
  animation: exploreAni 3s infinite;
}

.creative {
  background-color: var(--primary-color);
  justify-content: center;
  animation: creativeAni 3s infinite;
}
.expo-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: var(--dark);
  padding: 10px;
}

@keyframes exploreAni {
  25% {
    background-color: #545454;
  }

  50% {
    background-color: #54545465;
  }

  75% {
    background-color: #545454cc;
  }

  100% {
    background-color: #545454;
  }
}

@keyframes creativeAni {
  25% {
    background-color: #ec1414d9;
  }

  50% {
    background-color: #ec141430;
  }

  /* 75% {
      background-color: #ec1414bb;
    } */

  100% {
    background-color: #ec1414ea;
  }
}

@media screen and (max-width: 768px) {
  .expo-container {
    width: 100% !important;
  }
  .expo {
    /* width: 50% !important; */
    padding: 5px 50px;
    height: 100%;
    width: 30%;
  }
}

@media screen and (max-width: 428px) {
  .expo-container{
    flex-direction: column;
    gap: 8px;
  }
  .expo{
    width: 70%;
    padding: 25px 50px;
  }
}